import React from 'react'
import { User } from './types'

const UserContext = React.createContext<User | null | undefined>(undefined)

export const useUser = () => {
    const context = React.useContext(UserContext)

    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider')
    }

    return context
}

export const UserProvider: React.FC<{ user?: User | null }> = ({ user, children }) => {
    return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}
