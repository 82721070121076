import { Theme, createTheme } from '@mui/material'
import { PoolEventResponse } from './pool_types'

export type PoolEventTheme = {
    theme: Theme
    secondaryLogo?: string
    ogImage?: string
}

export type PoolEventThemeData = {
    headerBackgroundColor: string
    headerTextColor: string
    headerLogoColor: string
    primaryMainColor: string
    headerPrimaryMainColor: string
    primaryGradient?: string
}

export function getPoolEventThemeData(poolEvent?: PoolEventResponse): PoolEventThemeData | undefined {
    if (!poolEvent) {
        return undefined
    }

    const {
        headerBackgroundColor,
        headerLogoColor,
        headerPrimaryMainColor,
        headerTextColor,
        primaryMainColor,
    } = poolEvent

    return headerBackgroundColor &&
        headerLogoColor &&
        headerPrimaryMainColor &&
        headerTextColor &&
        primaryMainColor
        ? {
              headerBackgroundColor,
              headerLogoColor,
              headerPrimaryMainColor,
              headerTextColor,
              primaryMainColor,
          }
        : undefined
}

export function getDefaultPoolEventTheme(
    theme: Theme,
    poolEventThemeData: PoolEventThemeData
): PoolEventTheme {
    return {
        theme: createTheme({
            ...theme,
            palette: {
                ...theme.palette,
            },
        }),
    }
}
