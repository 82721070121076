import { getLocalizedValue } from '@/utils/i18n_client'
import { PoolEventResponse, PoolFixtureSports } from '@/utils/pool_types'
import { SvgIconTypeMap, SxProps, Theme, alpha, useTheme } from '@mui/material'
import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer'
import SportsHockeyIcon from '@mui/icons-material/SportsHockey'
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball'
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball'
import SportsFootballIcon from '@mui/icons-material/SportsFootball'
import SportsTennisIcon from '@mui/icons-material/SportsTennis'
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball'
import SportsGolfIcon from '@mui/icons-material/SportsGolf'
import SportsRugbyIcon from '@mui/icons-material/SportsRugby'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import LockIcon from '@mui/icons-material/Lock'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'
import { FyChip } from '../styled/Buttons'

export function getPoolEventSportIcon(
    sport: PoolFixtureSports,
    props?: DefaultComponentProps<SvgIconTypeMap>
): JSX.Element {
    switch (sport) {
        case 'baseball':
            return <SportsBaseballIcon {...props} />
        case 'basketball':
            return <SportsBasketballIcon {...props} />
        case 'football':
            return <SportsFootballIcon {...props} />
        case 'hockey':
            return <SportsHockeyIcon {...props} />
        case 'soccer':
            return <SportsSoccerIcon {...props} />
        case 'tennis':
            return <SportsTennisIcon {...props} />
        case 'volleyball':
            return <SportsVolleyballIcon {...props} />
        case 'golf':
            return <SportsGolfIcon {...props} />
        case 'rugby':
            return <SportsRugbyIcon {...props} />
        default:
            return <QuestionMarkIcon {...props} />
    }
}

export const PoolEventChip: FC<{
    poolEvent: PoolEventResponse
    inTheme?: boolean
}> = ({ poolEvent, inTheme = true }) => {
    const { i18n } = useTranslation('pools')
    const theme = useTheme()

    return (
        <FyChip
            icon={getPoolEventSportIcon(poolEvent.sport, {
                style: { color: theme.palette.common.poolEventHeaderLogo },
            })}
            clickable
            variant="filled"
            label={
                <>
                    {getLocalizedValue(poolEvent.name, i18n.language)}
                    {poolEvent.private && (
                        <>
                            &nbsp;
                            <LockIcon
                                fontSize="small"
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                        </>
                    )}
                </>
            }
        />
    )
}
