import { Button, ButtonProps, Chip, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ForwardedRef, forwardRef, useState } from 'react'
import ShortcutIcon from '@mui/icons-material/Shortcut'

export const FyChip = styled(Chip)((ctx) => ({
    boxShadow: 'none',
    textTransform: 'uppercase',
    fontSize: 14,
    padding: '2px 0px',
    height: 'auto',
    border: 0,
    borderRadius: '4px',
    backgroundColor: ctx.theme.palette.grey[200],
    color: '#000',
    '&:disabled': {
        color: '#ccc',
        boxShadow: 'none',
    },
    '&:hover': {
        border: 0,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ...ctx.style,
}))

export const BootstrapButton = styled(Button)((ctx) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    border: 0,
    backgroundColor: '#09c269',
    color: '#fff',
    fontWeight: 700,
    '&:disabled': {
        color: '#ccc',
        backgroundColor: ctx.theme.palette.grey[700],
        boxShadow: 'none',
    },
    '&:hover': {
        border: 0,
        backgroundColor: ctx.theme.palette.common.black,
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    ...ctx.style,
}))

export const FyActionButton = forwardRef<HTMLButtonElement, ButtonProps>(function ActionButton(
    props: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const { children, disabled, endIcon, ...other } = props
    const [loading, setLoading] = useState(false)

    return (
        <BootstrapButton
            ref={ref}
            {...other}
            onClick={async (e) => {
                setLoading(true)
                try {
                    other.onClick && (await other.onClick(e))
                } finally {
                    setLoading(false)
                }
            }}
            disabled={loading || disabled}
            endIcon={
                loading ? (
                    <CircularProgress size="16px" sx={{ color: '#fff' }} />
                ) : (
                    endIcon ?? <ShortcutIcon />
                )
            }
        >
            {children}
        </BootstrapButton>
    )
})

export const SecondaryButton = styled(Button)((ctx) => ({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    backgroundColor: '#7d3cff',
    color: '#fff',
    fontWeight: 700,
    '&:hover': {
        backgroundColor: ctx.theme.palette.common.black,
        boxShadow: 'none',
        backgroundImage: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
}))
